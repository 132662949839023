<template>
  <div class="payMentBox">
    <div v-if="gobegin">
      <div class="orderInfo">
        <div class="orderPrice">
          <div>￥<span class="price">{{orderInfo.price}}</span></div>
        </div>
        <div class="orderForm">
          <div>{{orderInfo.dealerName}}</div>
        </div>
        <div class="orderDetail">
          <div class="orderDetailItem">
            <div>商品数量:</div>
            <div>{{orderInfo.productNumber}}</div>
          </div>
          <div class="orderDetailItem">
            <div>订单编号:</div>
            <div>{{orderInfo.orderNo}}</div>
          </div>
          <div class="orderDetailItem">
            <div>订单时间:</div>
            <div>{{orderInfo.date}}</div>
          </div>
        </div>
      </div>
      <van-button class="payBtn" :disabled="disabled" color="#FF8200" @click="handleClickPayMent">马上支付</van-button>
    </div>
    <div v-else class="oneTip">
      订单已提交,微信内不支持支付宝支付，请点击右上角在浏览器中打开进行支付
    </div>
    <form method="POST" id="payForm" style="visibility:hidden;">
      <input type="text" value="" id="code" style="visibility:hidden;" name="code"/>
      <input type="text" value="" id="sysOfficeId" style="visibility:hidden;" name="sysOfficeId"/>
      <input type="text" value="" id="subject" style="visibility:hidden;" name="subject"/>
      <input type="text" value="" id="totalAmount" style="visibility:hidden;" name="totalAmount"/>
      <input type="text" value="" id="body" style="visibility:hidden;" name="body" align="bottom"/>
    </form>
    <!-- <div v-if="tipShow">
      <div>{{word}}</div>
      <div>{{word2}}</div>
    </div> -->
  </div>
</template>

<script>
import bshop_global from "@/libs/global"
import { payOrderInfo ,payOrderformAddress } from "@/apis/api"
  export default {
    data() {
      return {
        customerId:"",
        sysOfficeId:"",
        orderId:"",
        gobegin:false,
        tipShow:true,
        orderInfo:{},//订单信息
        disabled:true
      }
    },
    created() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; // android终端
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isiOS) {
        this.word = "由于微信限制，支付宝支付请点击右上角在Safari中打开进行支付";
        this.word2 = "在Safari中打开";
      }
      if (isAndroid) {
        this.word = "在浏览器中打开";
        this.word2 = "由于微信限制，支付宝支付请点击右上角在浏览器中打开进行支付";
      }
      if (this.isWeiXin()) {
        this.gobegin = false;
        // this.tipShow = true
      } else {
        this.gobegin = true;
        // this.tipShow = false;
        this.getPayOrderInfo();
      }
    },
    mounted() {
      this.orderId = this.$route.query.code;
      this.customerId = this.$route.query.customerId;
      this.sysOfficeId = this.$route.query.sysOfficeId;
      //下面存起来 是为了支付失败的时候 能够在支付失败的页面找到缓存中的 customerId sysOfficeId
      localStorage.setItem('customerId',this.customerId) 
      localStorage.setItem('sysOfficeId',this.sysOfficeId) 
    },
    methods: {
      // 判断是否是微信
      isWeiXin() {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
          window.isWeiXin = 1;
          return true;
        } else {
          return false;
        }
      },
      //如果是浏览器  就要获取订单信息
      getPayOrderInfo(){
        let data = {
            req:{
              orderId:this.$route.query.code
            },
            req_meta:{
                customerId:this.customerId,
                sysOfficeId:this.sysOfficeId
            }
        }
        payOrderInfo(data).then(res=>{
          if(res.code == 0){
            if(res.res.payStatus == 1){
              window.location.href = bshop_global.bShopUrl +"/#/alipay-success?orderno=" + res.res.orderNo
            }
            this.disabled = false;
            this.orderInfo = {
              orderNo:res.res.orderNo,
              date:res.res.date,
              dealerName:res.res.dealerName,
              price:res.res.price,
              productNumber:res.res.productNumber
            }
          }else{
            this.$toast(res.msg)
          }
        })
      },
      //马上支付
      handleClickPayMent(){
        //如果没有获取到订单信息 就不能支付
        if(this.orderInfo  == {}){
          return;
        }
        //判断是不是来自微信内置浏览器 如果是不允许支付
        if(this.isWeiXin()){
          this.$toast("当前环境为微信内置")
        }else{
          let f = $("#payForm");
          f.attr("action", payOrderformAddress());
          $("#code").val(this.orderInfo.orderNo);
          $("#sysOfficeId").val(this.sysOfficeId);
          $("#subject").val(this.orderInfo.dealerName+"在线商品");
          $("#totalAmount").val(this.orderInfo.price);
          $("#body").val("订购 " + this.orderInfo.dealerName + " 商品 " + this.orderInfo.productNumber + "件");
          f.submit();
        }
      }
    }
  }
</script>

<style scoped>
  .payMentBox{
    padding: 1rem;
    font-size: 0.88rem;
  }
  .payBtn{
    width: 100%;
    height: 2rem;
    line-height: 2rem
  }
  .orderInfo{
    margin-bottom: 2rem;
    width:  90%;
    padding: 1rem 5%;
    box-shadow: 0.1rem 0.1rem 1rem #eee;
  }
  .orderPrice{
    font-size: 1.8rem;
    text-align: center;
  }
  .orderForm{
    text-align: center;
    font-size: 0.8rem;
    color: #8a8a8a;
  }
  .orderDetail .orderDetailItem{
    display: flex;
    flex-wrap: wrap;
    margin: 0.5rem 0;
  }

  .oneTip{
    font-size: 0.88rem;
    margin-top: 2rem;
    text-align: center;
  }
</style>